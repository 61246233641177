import BlockLink from "../shared/components/BlockLink"

const Articles = () => {
    return (
        <div>
            <h1 style={{ marginLeft: '20px' }}>Killer Reads</h1>

            <BlockLink
                body="Some really great writing by other people: ideas good enough to stick around."
            />

            <hr style={{ marginLeft: '40px' }}/>

            <BlockLink
                body="Sometimes the most basic and non-general tool is the best tool for your simple job."
                link="Why I Hate Frameworks (or, Modern Software Development Summarized)"
                href="https://archive.ph/2nyoe"
            />

            <BlockLink
                body="Being fast at things makes it more likely that you'll try new and more things - speed is a human feature."
                link="Speed matters: Why working quickly is more important than it seems"
                href="https://archive.ph/7z4cd"
            />

            <BlockLink
                body="How to pitch a company with writing instead of a deck; how not to do financial planning"
                link="Viaweb's First Business Plan"
                href="https://archive.ph/7N9jA"
            />

            <BlockLink
                body="Learn deeply by occasionally suspending trust and diving in deep to primary sources."
                link="Minimal-Trust Investigations"
                href="https://archive.ph/USkq8"
            />
            
            <BlockLink
                body="A call to do better things"
                link="Choose Good Quests"
                href="https://archive.ph/XbGah"
            />
            
            <BlockLink
                body="Building better opinions by reading <for> writing"
                link="Learning by Writing"
                href="https://archive.ph/Oko4m"
            />

            <BlockLink
                body="Productivity advice without all the junk."
                link="Advice That Actually Worked for Me"
                href="https://archive.ph/EkQEJ"
            />

            <BlockLink
                body="A beautiful tale of a beautiful working relationship between Jeff Dean and Sanjay Ghemawat."
                link="The Friendship That Made Google Huge"
                href="https://archive.ph/xqPCC"
            />

            <BlockLink
                body="This is how you think through a business and explain a brilliant future - and the path to get there."
                link="Terraform Industries Whitepaper 2.0"
                href="https://archive.ph/F3Pqc"
            />
            
            <BlockLink
                body="Work on grand "
                link="Do Quests, not Goals"
                href="https://archive.ph/XbGah"
            />

            <BlockLink
                body="Don't be the product - own your thoughts and conversations."
                link="Bring back personal blogging - Colm Doyle"
                href="https://archive.ph/MRSqT"
            />

            <BlockLink
                body="Paul Graham's 13 sentences for startups"
                link="13 sentences for startups"
                href="https://archive.ph/WB653"
            />

            <BlockLink
                body="Principles for managing - progress is most important"
                link="Principles of Progress"
                href="https://archive.ph/qC861"
            />

            <BlockLink
                body="Rise of the Silicon Valley Small Business"
                link="Anu Atluru"
                href="https://archive.ph/OTFw1"
            />

        </div>
    )
}
export default Articles