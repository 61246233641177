import { useEffect, useState } from "react";
import BlockLink from "../shared/components/BlockLink";
import {
  getNewsQueue,
  getNewsQueueInfo,
} from "../shared/functions/getNewsQueue";

const Entries = () => {
  const [entries, setEntriesData] = useState([]);
  const [info, setEntriesInfo] = useState([]);

  function load() {
    const entriesData = getNewsQueue();
    entriesData.then((x) => {
      x.sort(function (a, b) {
        return new Date(b.added * 1000) - new Date(a.added * 1000);
      });
      setEntriesData(x);
    });

    const entriesInfo = getNewsQueueInfo();
    entriesInfo.then((x) => {
      setEntriesInfo(x);
    });
  }

  useEffect(() => {
    load();
  }, []);

  async function removeArticle(clicked) {
    // console.log("remove button clicked: " + clicked.target.id);

    var queue_article_id = clicked.target.id.split("-")[1];
    // console.log(queue_article_id);

    var api_url = "https://russell-article-saver.fly.dev/removeArticleQueue";
    var secret = prompt("Enter the secret key");

    const res = await fetch(api_url, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        id: queue_article_id,
        secret: secret,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const content = await res.json();
    // console.log(content);

    if (res.status !== 200) {
      alert(content.error);
      return;
    }

    // reload the content
    load();
  }

  return (
    <div>
      <p>Added: {info.added}</p>
      <p>Deleted: {info.deleted}</p>
      <div>
        {entries.map((entry) => (
          <div key={entry.name + "-div"}>
            <p key={entry.name + "-p"}>
              <i>
                {new Date(entry.added * 1000).toISOString().substring(0, 10)}
              </i>
              <button
                id={"remove-" + entry.id}
                style={{ float: "right" }}
                onClick={removeArticle}
              >
                Remove
              </button>
              <br />
              <a href={entry.url} key={entry.name + "-a"}>
                <strong>{entry.name}</strong>
              </a>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

const NewsQueue = () => {
  return (
    <div>
      <h1 style={{ marginLeft: "20px" }}>Reading Queue</h1>

      <BlockLink
                body="This is a live feed of the articles I haven't read yet. It helps me close tabs.
                It runs on a custom Chrome extension and backend."
                // You can use it yourself by following the instructions at the link below."
                // link="Github: Chrome extension + Python app"
                // href="https://github.com/russellromney"
            />

      <hr style={{ marginLeft: "40px" }} />

      <div style={{ marginLeft: "40px" }}>
        <Entries />
      </div>
    </div>
  );
};

export default NewsQueue;
