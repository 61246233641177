import { useEffect, useState } from "react"
import BlockLink from "../shared/components/BlockLink"
import getNewsFeed from "../shared/functions/getNewsFeed"


const Entries = () => {
    const [entries, setEntriesData] = useState([])

    useEffect(() => {
        const entriesData = getNewsFeed()
        entriesData.then((x) => {
            x.sort(function (a, b) {
                return new Date(b.added * 1000) - new Date(a.added * 1000);
            });
            setEntriesData(x)
        })
    }, [])

    return (
        <div>
            {
                entries.map(entry =>
                (
                    <div key={entry.name+"-div"}>
                        <p key={entry.name+"-p1"}>
                            <i>{new Date(entry.added * 1000).toISOString().substring(0, 10)}</i>
                            <br/>
                            <a href={entry.url} key={entry.name+"-a"}>
                            <strong>{entry.name}</strong>
                            </a>
                            <br/>
                            <div>{entry.description}</div>
                        </p>
                    </div>
                )
                )
            }
        </div>
    )
}

const NewsFeed = () => {
    return (
        <div>
            <h1 style={{ marginLeft: '20px' }}>Reading Feed</h1>
            {/* <BlockLink
                body="Things I'm Reading Online."
            /> */}
      <BlockLink
                body="This is a live feed of some of the articles I'm reading. It helps me understand my media diet. 
                It runs on a custom Chrome extension and backend."
                // You can use it yourself by following the instructions at the link below."
                // link="Github: Chrome extension + Python app"
                // href="https://github.com/russellromney"
            />


            <hr style={{ marginLeft: '40px' }} />

            <div style={{ marginLeft: '40px' }}>
                <Entries />
            </div>
        </div>
    )
}

export default NewsFeed